import type { Address } from "@paparazzi/types/paparazzi.api.serializers";

export const typeOptions = [
  "Necklaces",
  "Earrings",
  "Bracelets",
  "Rings",
  "Anklets",
  "Hair Accessories",
];

export const extraIdentifiers = [
  "Clip-Ons",
  "Hoops",
  "Initials",
  "Posts",
  "Choker",
  "Cuffs/Crawlers",
  "Lanyards",
  "Fashion Fix",
  "Anklet",
  "Pink Diamond",
  "Black Diamond",
  "Encore",
  "Cuff Links",
  "Empire Diamond",
  "Empire Diamond Design",
  "Zi Collection",
  "Signature Zi Collection",
  "Blockbusters",
  "Hair Clips",
  "Men's",
];

export const metalOptions = [
  { key: "BR", label: "Brass" },
  { key: "CP", label: "Copper" },
  { key: "GD", label: "Gold" },
  { key: "SV", label: "Silver" },
  { key: "UR", label: "Mixed" },
];

export const colorOptions = [
  { key: "BK", label: "Black" },
  { key: "BL", label: "Blue" },
  { key: "BN", label: "Brown" },
  { key: "GR", label: "Green" },
  { key: "OG", label: "Orange" },
  { key: "PK", label: "Pink" },
  { key: "PR", label: "Purple" },
  { key: "RD", label: "Red" },
  { key: "WT", label: "White" },
  { key: "YW", label: "Yellow" },
  { key: "MT", label: "Multi" },
];

export const colorLabels = [
  ...metalOptions.map((i) => i.label),
  ...colorOptions.map((i) => i.label),
];

export const productLabels = [
  ...typeOptions,
  ...extraIdentifiers,
  ...colorLabels,
];

export interface Mirror {
  url: string;
  removeUrl?: string;
  name: string;
  data: any;
  limit?: number;
  after?: string;
  order?: "value";
  useValue?: boolean;
  userRequired?: boolean;
}

export interface Category {
  id: number;
  parent: number;
  name: string;
  retail_name: string | null;
  slug: string;
  active: boolean;
  indexed: boolean;
  allow_all: boolean;
  allowed_groups: string[];
  inventory_source: string;
  release_date?: Record<string, string>;
}

export interface SubProduct {
  id: number;
  active: boolean;
  stock: number;
  slug: string;
  option: string;
  ordering: number;
  parent: number;
  child: number;
}

export interface ProductVideo {
  id: number;
  order: number;
  url: string;
}

export interface Product {
  id: number;
  slug: string;
  name: string;
  description: string;
  active: boolean;
  stock: number;
  volume: string;
  remote_id: string;
  is_parent: boolean;
  subproducts?: SubProduct[];
  images: any[];
  videos: ProductVideo[];
  parent: number;
  category: number;
  categories: number[];
  date_added: string;
  inventory_source: string;
  release_date: string;
  release_dates?: Record<string, string>;
  complete_look: Product[];
  cl_refs: number[];
  prices: {
    wholesale?: number;
    retail?: number;
    null?: number;
  };
  colors?: string[];
  extras?: {
    eventforms?: any[];
  };

  // generated
  deployed: Date;
}

export enum PromoType {
  local_userchoice = "local_userchoice",
  local_gift = "local_gift",
  local_indexed_gift = "local_indexed_gift",
  local_batched = "local_batched",
}

export interface PromotionFilter {
  id: number;
  module?: string;
  value: number;
}

export interface Promotion {
  id: number;
  volume: number;
  cumulative: boolean;
  cumulative_max: number;
  cumulative_offset: number;
  description: string;
  min_volume: number;
  max_volume: number;
  coupon_code: string;
  has_voucher: boolean;
  promo_type: PromoType;
  group: string;
  exclude_group: string;
  timestamp?: Date;
  extra?: any;
  resolve?: Function;
  resolved?: boolean;
  limit_to: string;
  limit_freebies_to: string;
  shipper?: number;
  shipper_name?: string;
  limit_categories?: number[];
  filters?: PromotionFilter[];
}

export interface AddCartItem {
  product_id?: number;
  slug?: string;
  quantity?: number;
  product?: Product;
  promotion?: number;
  extras?: {
    eventforms?: any;
  };
}
export interface AddCartItemSlug extends AddCartItem {
  product_id?: never;
  slug: string;
}
export interface AddCartItemId extends AddCartItem {
  product_id: number;
  slug?: never;
}

export interface CartItem {
  id: number;
  quantity: number;
  product: Product;
  promotion?: number;
  extras?: any[];
}

export interface Cart {
  id?: number;
  items: CartItem[];
  modified?: number;
  coupon_code?: string;
  checkout?: boolean;
  checked?: boolean;
  shipping_address?: Address;
  billing_address?: Address;
  shipping_choice?: string;
  shippingPromos?: Shipper[];
  changed: string;
  totals: number;
}

export interface Shipper {
  ShipMethodID: string;
  Price: number;
  Description: string;
  InventorySource: string;
  extra?: string;
}
