<template>
  <footer>
    <div class="inside">
      <div class="top-row">
        <div class="link-block" :class="{ open: open === 'company' }">
          <h4 @click="toggleOpen('company')">Company</h4>
          <RouterLink to="/about">About Us</RouterLink>
          <RouterLink to="/founders">Our Story</RouterLink>
          <a
            href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=740b7487-5335-4a8f-a1bd-bb0ea721c0fb&ccId=19000101_000001&type=MP&lang=en_US"
            target="_blank"
            >Careers</a
          >
        </div>
        <div class="link-block" :class="{ open: open === 'support' }">
          <h4 @click="toggleOpen('support')">Support</h4>
          <RouterLink to="/faqs">FAQs</RouterLink>
          <RouterLink to="/returns/policy">Return Policy</RouterLink>
          <RouterLink to="/shipping-policy">Shipping Policy</RouterLink>
        </div>
        <div class="link-block" :class="{ open: open === 'contact' }">
          <h4 @click="toggleOpen('contact')">Contact</h4>
          <!-- <i class="pi pi-chevron-down"></i> -->
          <RouterLink to="/pages/contact-us">Contact Us</RouterLink>
          <!-- <a href="mailto: support@paparazziaccessories.com">Email</a> -->
          <a href="tel: 8556972727">Phone: 855.697.2727</a>
          <a v-if="user.isRep" href="#" @click.prevent="openBeacon"
            >Live Chat</a
          >
        </div>
        <div class="link-block" :class="{ open: open === 'policies' }">
          <h4 @click="toggleOpen('policies')">Policies</h4>
          <a
            href="https://paparazziaccessories.com/media/static/site/documents/paparazzi-incomedisclosurestatement.pdf" target="_blank"
            >Income Disclosure Statement</a
          >
          <RouterLink to="/pages/privacy">Privacy Policy</RouterLink>
          <RouterLink to="/pages/terms-of-service">Terms of Service</RouterLink>
          <RouterLink to="/information/ccpa">CCPA</RouterLink>
          <a
            href="https://storage.googleapis.com/vw-paparazzi/uploads/2024/11/12/files/CCPARequestForm.pdf" target="_blank"
            >CCPA Form</a
          >
        </div>
        <div class="flex-stretch" />
        <div class="icons social-media">
          <a
            href="https://www.facebook.com/PaparazziAccessories"
            target="_blank"
            class="facebook"
            ><img
              src="@/assets/social/SocialMedia_Facebook.svg"
              aria-label="Facebook"
          /></a>
          <!-- <Button text rounded aria-label="facebook" icon="pi pi-facebook" /> -->
          <a href="https://x.com/paparazziaccess" target="_blank" class="x"
            ><img
              src="@/assets/social/SocialMedia_X.svg"
              aria-label="twitter (x)"
          /></a>
          <a
            href="https://www.instagram.com/paparazziaccessories/"
            target="_blank"
            class="instagram"
            ><img
              src="@/assets/social/SocialMedia_Instagram.svg"
              aria-label="Instagram"
          /></a>
          <a
            href="https://www.pinterest.com/paparazziaccessoriesllc/"
            target="_blank"
            class="pinterest"
            ><img
              src="@/assets/social/SocialMedia_Pinterest.svg"
              aria-label="Pinterest"
          /></a>
          <a
            href="https://www.youtube.com/user/PaparazziAccessories"
            target="_blank"
            class="youtube"
            ><img
              src="@/assets/social/SocialMedia_YouTube.svg"
              aria-label="YouTube"
          /></a>
          <a
            href="https://www.linkedin.com/company/paparazziaccessories"
            target="_blank"
            class="linkedin"
            ><img
              src="@/assets/social/SocialMedia_InDesign.svg"
              aria-label="LinkedIn"
          /></a>
        </div>
      </div>
      <div class="copyright flex-row flex-center">
        &copy; {{ new Date().getFullYear() }} Paparazzi Accessories. All Rights
        Reserved.
        <!-- <span> | {{ version }}</span> -->
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { ref } from "vue";

const user = useUserStore();

const open = ref("");

function toggleOpen(label: string) {
  if (open.value === label) {
    open.value = "";
  } else {
    open.value = label;
  }
}

function openBeacon(e: MouseEvent) {
  if (window.Beacon) {
    window.Beacon("open");
  }
}
</script>

<style scoped>
footer {
  margin: auto;
  max-width: var(--Max-page-widths-max-width);
  display: flex;
  flex-direction: column;
  padding: var(--3XL, 80px) 0 var(--1XL, 64px) 0;
  gap: var(--md, 32px);
}
h4 {
  color: var(--Black, var(--Black, #000));
  padding: 8px;
  margin: 0;

  /* Heading 4 */
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
.link-block a {
  padding: 10px;
  color: var(--Black, var(--Black, #000));
  white-space: nowrap;
  transition: 0.2s ease;
  /* Small Print */
  font-family: Indivisible;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link-block a:hover {
  text-shadow: #000 0 0.25px;
}
.inside {
  border-top: 1px solid #d0d0d0;
  display: flex;
  max-width: var(--Max-page-widths-max-width);
  flex-direction: column;
  padding-top: 40px;
  gap: 40px;
}
.link-block {
  display: flex;
  flex-direction: column;
  padding-right: var(--md, 32px);
}
.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.social-media .p-button {
  background: transparent;
  transition: all 0.1s ease;
}

.social-media a.facebook img,
.social-media a.pinterest img,
.social-media a.x img,
.social-media a.instagram img,
.social-media a.youtube img,
.social-media a.linkedin img {
  opacity: 1;
  transition: all 0.1s ease;
}

.social-media a.facebook img:hover,
.social-media a.pinterest img:hover,
.social-media a.x img:hover,
.social-media a.instagram img:hover,
.social-media a.youtube img:hover,
.social-media a.linkedin img:hover {
  opacity: 0.6;
}

.social-media a.facebook {
  display: inline-block;
  /* padding: 3px 8px 3px 8px; */
  img {
    /* padding: 1px; */
  }
}

.social-media a.pinterest {
  display: inline-block;
  /* padding: 3px 10px 3px 10px; */
  img {
    /* padding: 1px; */
  }
}

.social-media .p-button:hover {
  background: transparent;
  /* color: var(--Paparazzi-Pink, #ec008c); */
  opacity: 0.6;
}
.icons {
  width: 256px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icons img {
  height: 20px;
  width: 20px;
}
.copyright {
  color: var(--50, #939598);
  text-align: center;
  font-family: Indivisible;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1199px) {
  .inside {
    padding: 40px 20px;
  }
}
@media (max-width: 800px) {
  footer {
    padding: var(--1XL, 64px) 0 var(--lg, 48px);
  }
  .inside {
    padding: var(--md, 32px) var(--sm, 16px);
  }

  .top-row {
    flex-direction: column;
  }

  .link-block {
    padding: 0 0 var(--2XS, 8px);
    flex: 0 0 auto;
  }
  h4 {
    padding: 7px 0;
  }
  h4::after {
    font-size: 22px;
    margin-left: var(--XS, 16px);
  }
  .icons {
    margin: auto;
    margin-top: var(--Sm, 24px);
  }

  .icons.social-media {
    margin-top: 24px;
  }

  .link-block.open h4:after {
    content: "⌄";
    font-size: 18px;
    position: relative;
    top: -4px;
  }
  .link-block h4:after {
    content: "›";
    padding: 0 var(--2XS, 8px);
  }
  .link-block a {
    display: none;
    padding: 12px 8px 12px 0;
  }
  .link-block.open a {
    display: block;
  }
}
</style>
